@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

:root {
    /* Colors */
    --primary-color: #0A2239;
    --primary-tint-color: #23384d;
    --secondary-color: #1D84B5;
    --black-color: #000;
    --text-color: #171515;
    --white-color: #fff;
    --grey-color: #f7f7f7;
    --light-grey-color: #f2f2f2;
    --dark-grey-color: #cfcfcf;

    /* Spacing */
    --spacing-1: 0.2rem; /* Equivalent to 2px (10px * 0.2) */
    --spacing-2: 0.4rem; /* Equivalent to 4px (10px * 0.4) */
    --spacing-3: 0.6rem; /* Equivalent to 6px (10px * 0.6) */
    --spacing-4: 1rem; /* Equivalent to 10px (10px * 1) */
    --spacing-5: 1.6rem; /* Equivalent to 16px (10px * 1.6) */
    --spacing-6: 2.4rem; /* Equivalent to 24px (10px * 2.4) */
    --spacing-7: 3.2rem; /* Equivalent to 32px (10px * 3.2) */
    --spacing-8: 4.8rem; /* Equivalent to 48px (10px * 4.8) */
    --spacing-9: 6.4rem; /* Equivalent to 64px (10px * 6.4) */
    --spacing-10: 8rem; /* Equivalent to 80px (10px * 8) */
    --spacing-11: 9.6rem; /* Equivalent to 96px (10px * 9.6) */
    --spacing-12: 12.8rem; /* Equivalent to 128px (10px * 12.8) */

    /* Font size */
    --font-10: 1rem; /* Equivalent to 10px (10px * 1) */
    --font-12: 1.2rem; /* Equivalent to 12px (10px * 1.2) */
    --font-14: 1.4rem; /* Equivalent to 14px (10px * 1.4) */
    --font-16: 1.6rem; /* Equivalent to 16px (10px * 1.6) */
    --font-18: 1.8rem; /* Equivalent to 18px (10px * 1.8) */
    --font-20: 2rem; /* Equivalent to 20px (10px * 2) */
    --font-24: 2.4rem; /* Equivalent to 24px (10px * 2.4) */
    --font-30: 3rem; /* Equivalent to 30px (10px * 3) */
    --font-36: 3.6rem; /* Equivalent to 36px (10px * 3.6) */
    --font-44: 4.4rem; /* Equivalent to 44px (10px * 4.4) */
    --font-52: 5.2rem; /* Equivalent to 52px (10px * 5.2) */
    --font-62: 6.2rem; /* Equivalent to 62px (10px * 6.2) */
    --font-74: 7.4rem; /* Equivalent to 74px (10px * 7.4) */
    --font-86: 8.6rem; /* Equivalent to 86px (10px * 8.6) */
    --font-98: 9.8rem; /* Equivalent to 98px (10px * 9.8) */

    /* Font weight */
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;

    /* Shadows */
    --shadow-xxsmall: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1);
    --shadow-xsmall: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
    --shadow-small: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.1);
    --shadow-medium: 0 4.8rem 9.6rem rgba(0, 0, 0, 0.1);
    --shadow-large: 0 9.6rem 16.8rem rgba(0, 0, 0, 0.1);
    --shadow-xlarge: 0 16.8rem 25.6rem rgba(0, 0, 0, 0.1);
    --shadow-xxlarge: 0 25.6rem 37.6rem rgba(0, 0, 0, 0.1);
}


.container {
    position: relative;
    flex: 1; /* Take up remaining vertical space within the wrapper */
    margin: 0 auto;
    width: 100%; /* If you want to limit the width */
    padding: 4.2rem 3.2rem;

    /*overflow-y: hidden;*/
}

/*Hero text*/
.head-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.head-text-container {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-7);
}

.hero-description {
    color: var(--text-color);
    margin-bottom: var(--spacing-6);
    line-height: var(--spacing-7);
    font-size: var(--font-18);
    font-weight: var(--font-weight-500);
}

.cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-7);
}

.login--container ,
.register--container  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-7);
}

.login--container  input,
.register--container  input {
    padding: var(--spacing-4) var(--spacing-6);
    border: 1px solid var(--primary-color);
    font-size: var(--font-16);
    font-weight: var(--font-weight-500);
    color: var(--black-color);
    background-color: transparent;
    outline: none;
    transition: all 0.5s;
}

.message {
    margin-bottom: var(--spacing-7);
    font-size: var(--font-36);
    font-weight: var(--font-weight-800);
}

.task-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1
}


.task-list {
    list-style: none;
    padding: 2.4rem 3.2rem;
    display: flex;
    flex-direction: column;
    flex: 1; /* Make .task-list take up the remaining height */
    /* other styles for .task-list */
}

.task-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 6rem;
    border-radius: 5px;
    box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.task-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
}

.task-form__title-input,
.task-form__description-input {
    padding: 1.2rem;
    border: 1px solid var(--primary-color);
    outline: none;
    min-width: 45rem;
}

.task-item {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    gap: 1.2rem;
    padding: 1.2rem 0;
}

.task-item:hover {
    background-color: #bbdae9;
    cursor: pointer;
}

.task-item--done {
    background-color: #d2e6f0;
    opacity: 0.2;
    transition: all 0.5s;
    pointer-events: none;
}

.task-item--done:hover {
    background-color: #bbdae9;
    cursor: pointer;
}

.task-status {
    appearance: none;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    width: 2.4rem;
    height: 2.4rem;
    transition: 0.2s all linear;
    position: relative;
    justify-self: center;
}

.task-status:checked {
    border: 1px solid var(--primary-color);
}

.task-status:checked::before {
    content: '';
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    background: var(--primary-color);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.task-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.task-title {
    font-size: var(--font-18);
    font-weight: var(--font-weight-800);
}

.task-description {
    font-size: var(--font-16);
    font-weight: var(--font-weight-400);
}

.task-due-date {
    font-size: var(--font-16);
    font-weight: var(--font-weight-400);
}

.edit-task-btn {
    text-decoration: none;
    font-weight: var(--font-weight-500);
    transition: all 0.5s;
}

.edit-task-btn:hover {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-radius: var(--spacing-8);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}




