@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap";
:root {
  --primary-color: #0a2239;
  --primary-tint-color: #23384d;
  --secondary-color: #1d84b5;
  --black-color: #000;
  --text-color: #171515;
  --white-color: #fff;
  --grey-color: #f7f7f7;
  --light-grey-color: #f2f2f2;
  --dark-grey-color: #cfcfcf;
  --spacing-1: .2rem;
  --spacing-2: .4rem;
  --spacing-3: .6rem;
  --spacing-4: 1rem;
  --spacing-5: 1.6rem;
  --spacing-6: 2.4rem;
  --spacing-7: 3.2rem;
  --spacing-8: 4.8rem;
  --spacing-9: 6.4rem;
  --spacing-10: 8rem;
  --spacing-11: 9.6rem;
  --spacing-12: 12.8rem;
  --font-10: 1rem;
  --font-12: 1.2rem;
  --font-14: 1.4rem;
  --font-16: 1.6rem;
  --font-18: 1.8rem;
  --font-20: 2rem;
  --font-24: 2.4rem;
  --font-30: 3rem;
  --font-36: 3.6rem;
  --font-44: 4.4rem;
  --font-52: 5.2rem;
  --font-62: 6.2rem;
  --font-74: 7.4rem;
  --font-86: 8.6rem;
  --font-98: 9.8rem;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --shadow-xxsmall: 0 .8rem 1.6rem #0000001a;
  --shadow-xsmall: 0 1.2rem 2.4rem #0000001a;
  --shadow-small: 0 2.4rem 4.8rem #0000001a;
  --shadow-medium: 0 4.8rem 9.6rem #0000001a;
  --shadow-large: 0 9.6rem 16.8rem #0000001a;
  --shadow-xlarge: 0 16.8rem 25.6rem #0000001a;
  --shadow-xxlarge: 0 25.6rem 37.6rem #0000001a;
}

.container {
  flex: 1;
  width: 100%;
  margin: 0 auto;
  padding: 4.2rem 3.2rem;
  position: relative;
}

.head-container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.head-text-container {
  margin-bottom: var(--spacing-7);
  flex-direction: column;
  display: flex;
}

.hero-description {
  color: var(--text-color);
  margin-bottom: var(--spacing-6);
  line-height: var(--spacing-7);
  font-size: var(--font-18);
  font-weight: var(--font-weight-500);
}

.cta-container {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-7);
  display: flex;
}

.login--container, .register--container {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-7);
  flex-direction: row;
  display: flex;
}

.login--container input, .register--container input {
  padding: var(--spacing-4) var(--spacing-6);
  border: 1px solid var(--primary-color);
  font-size: var(--font-16);
  font-weight: var(--font-weight-500);
  color: var(--black-color);
  background-color: #0000;
  outline: none;
  transition: all .5s;
}

.message {
  margin-bottom: var(--spacing-7);
  font-size: var(--font-36);
  font-weight: var(--font-weight-800);
}

.task-container {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.task-list {
  flex-direction: column;
  flex: 1;
  padding: 2.4rem 3.2rem;
  list-style: none;
  display: flex;
}

.task-modal {
  z-index: 10;
  background-color: #fff;
  border-radius: 5px;
  padding: 6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3rem 5rem #0000004d;
}

.task-form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  display: flex;
}

.task-form__title-input, .task-form__description-input {
  border: 1px solid var(--primary-color);
  outline: none;
  min-width: 45rem;
  padding: 1.2rem;
}

.task-item {
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  gap: 1.2rem;
  padding: 1.2rem 0;
  display: grid;
}

.task-item:hover {
  cursor: pointer;
  background-color: #bbdae9;
}

.task-item--done {
  opacity: .2;
  pointer-events: none;
  background-color: #d2e6f0;
  transition: all .5s;
}

.task-item--done:hover {
  cursor: pointer;
  background-color: #bbdae9;
}

.task-status {
  appearance: none;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  justify-self: center;
  width: 2.4rem;
  height: 2.4rem;
  transition: all .2s linear;
  position: relative;
}

.task-status:checked {
  border: 1px solid var(--primary-color);
}

.task-status:checked:before {
  content: "";
  background: var(--primary-color);
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.task-content {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.task-title {
  font-size: var(--font-18);
  font-weight: var(--font-weight-800);
}

.task-description, .task-due-date {
  font-size: var(--font-16);
  font-weight: var(--font-weight-400);
}

.edit-task-btn {
  font-weight: var(--font-weight-500);
  text-decoration: none;
  transition: all .5s;
}

.edit-task-btn:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: var(--spacing-8);
  box-shadow: 0 0 20px #0003;
}

/*# sourceMappingURL=index.8ffac5ed.css.map */
